// extracted by mini-css-extract-plugin
export var lbContainerOuter = "C_tQ";
export var lbContainerInner = "C_tR";
export var movingForwards = "C_tS";
export var movingForwardsOther = "C_tT";
export var movingBackwards = "C_tV";
export var movingBackwardsOther = "C_tW";
export var lbImage = "C_tX";
export var lbOtherImage = "C_tY";
export var prevButton = "C_tZ";
export var nextButton = "C_t0";
export var closing = "C_t1";
export var appear = "C_t2";