// extracted by mini-css-extract-plugin
export var alignLeft = "s_qh d_bG";
export var alignCenter = "s_bP d_bD";
export var alignRight = "s_qj d_bH";
export var textAlignLeft = "s_sh";
export var textAlignCenter = "s_sj";
export var textAlignRight = "s_sk";
export var embedInnerWrapperDesign1 = "s_sl d_bM";
export var embed = "s_sm d_b1";
export var titleMargin = "s_sn d_cw d_dw";
export var subtitleMargin = "s_sp d_cs d_dw";
export var paragraphMargin = "s_sq d_cw d_dw";
export var SubtitleSmall = "s_qd q_qd q_qr q_qD";
export var SubtitleNormal = "s_qf q_qf q_qr q_qF";
export var SubtitleLarge = "s_qg q_qg q_qr q_qG";
export var BodySmall = "s_q9 q_q9 q_qr q_qL";
export var BodyNormal = "s_rb q_rb q_qr q_qM";
export var BodyLarge = "s_rc q_rc q_qr q_qN";