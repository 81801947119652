// extracted by mini-css-extract-plugin
export var alignLeft = "w_qh d_fp d_bG d_dv";
export var alignCenter = "w_bP d_fq d_bD d_dw";
export var alignRight = "w_qj d_fr d_bH d_dx";
export var alignColumnLeft = "w_ss d_fs d_bN d_dv";
export var alignColumnCenter = "w_st d_ft d_bP d_dw";
export var alignColumnRight = "w_sv d_fv d_bQ d_dx";
export var milestonesContainer = "w_sG d_dW";
export var milestonesContainerFull = "w_sH d_dT";
export var milestonesSubtitle = "w_mf d_mf d_cs";
export var milestonesComponentWrapper = "w_mb d_mb d_cv";
export var compContentWrapper = "w_sy d_mc d_bz d_bJ d_bM d_bF d_c7 d_H";
export var milestonesMainHeader = "w_l8 d_l8 d_w d_cr";
export var milestonesSubHeader = "w_l9 d_l9 d_w";
export var milestonesComponentWrapperRow = "w_sJ d_H";
export var milestonesBlockWrapper = "w_sK";
export var milestonesTextBlockWrapper = "w_sL";
export var milestonesComponentHeader = "w_md d_md d_w d_cr";
export var milestonesComponentParagraph = "w_mj d_mj d_w";
export var btnWrapper = "w_d2 d_bz d_ck";
export var btnWrapperCards = "w_sB d_bz d_cd";
export var imageWrapper = "w_sM d_cs d_bz";
export var Title1Small = "w_qX q_qX q_qr q_qs";
export var Title1Normal = "w_qY q_qY q_qr q_qt";
export var Title1Large = "w_qZ q_qZ q_qr q_qv";
export var Title2Small = "w_q0 q_q0 q_qr q_qw";
export var Title2Normal = "w_q1 q_q1 q_qr q_qx";
export var Title2Large = "w_q2 q_q2 q_qr q_qy";
export var SubtitleSmall = "w_qd q_qd q_qr q_qD";
export var SubtitleNormal = "w_qf q_qf q_qr q_qF";
export var SubtitleLarge = "w_qg q_qg q_qr q_qG";
export var Subtitle2Small = "w_q6 q_q6 q_qr q_qH";
export var Subtitle2Normal = "w_q7 q_q7 q_qr q_qJ";
export var Subtitle2Large = "w_q8 q_q8 q_qr q_qK";
export var BodySmall = "w_q9 q_q9 q_qr q_qL";
export var BodyNormal = "w_rb q_rb q_qr q_qM";
export var BodyLarge = "w_rc q_rc q_qr q_qN";
export var exceptionLineHeight = "w_sN q_qV";