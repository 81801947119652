// extracted by mini-css-extract-plugin
export var alignLeft = "v_qh d_fp d_bG d_dv";
export var alignCenter = "v_bP d_fq d_bD d_dw";
export var alignRight = "v_qj d_fr d_bH d_dx";
export var menuContainer = "v_sD d_dW";
export var menuContainerFull = "v_sF d_dT";
export var menuMainHeader = "v_jC d_jC d_w d_c3";
export var menuComponentWrapper = "v_jG d_jG d_cv";
export var menuComponentWrapperDesign2 = "v_jH d_jH d_cy";
export var menuComponentText = "v_jD d_jD d_cn";
export var menuComponentTextDesign2 = "v_jF d_jF d_cs";
export var menuImageWrapperDesign2 = "v_jJ d_jJ d_w d_Z";